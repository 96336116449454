<template>
  <div>
    <div class="goods-info-container">
      <div class="goods-info-container__left">
        <van-image :src="goods.after_goods.thumb_image" />
      </div>
      <div class="goods-info-container__right">
        <div class="goods-name">
          {{ goods.after_goods.name }}
        </div>
        <div class="size">
          规格：1*{{ goods.after_goods.size }}
        </div>
        <div v-if="goods.good_quantity > 0" class="volume">
          完好的差：<van-tag type="success">{{ goods.good_quantity }}{{ goods.after_goods.least_unit }}</van-tag>
        </div>
        <div v-if="goods.bad_quantity > 0" class="volume">
          损坏的差：<van-tag type="danger">{{ goods.bad_quantity }}{{ goods.after_goods.least_unit }}</van-tag>
        </div>
        <template v-if="showRemark">
          <div class="size">
            退货备注：{{ goods.after_goods.remark }}
          </div>
          <div class="size">
            验收备注：{{ goods.after_goods.accept_remark }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goods: {
      type: Object,
      required: true
    },
    showRemark: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/color.scss";
.goods-info-container {
  background-color: #fff;
  padding: 10px;
  display: flex;
  &__left {
    width: 80px;
    .van-image {
      width: 80px;
      height: 80px;
    }
  }
  &__right {
    margin-left: 10px;
    width: 100%;
    word-break: break-all;
    .size {
      font-size: 13px;
      color: $grayTextColor;
    }
    .volume {
      font-size: 13px;
      color: $grayTextColor;
    }
    .amount {
      margin-left: 10px;
      font-size: 13px;
      color: $amountRedColor;
    }
  }
}
</style>
