import request from '@/utils/request'

export default {

  list() {
    return request({
      method: 'get',
      url: 'settle/handle'
    })
  },
  handle(id, data) {
    return request({
      method: 'post',
      url: `settle/handle/${id}`,
      data
    })
  }

}

export function getRecords(params) {
  return request({
    method: 'get',
    url: `settle/handle/record`,
    params
  })
}

export function getRetrieves(params) {
  return request({
    method: 'get',
    url: 'settle/handle/retrieves',
    params
  })
}

export function getCanRetrieve(params) {
  return request({
    method: 'get',
    url: 'settle/handle/can-retrieve',
    params
  })
}

export function retrieveAfterGoods(data) {
  return request({
    method: 'post',
    url: 'settle/handle/retrieve',
    data
  })
}

export function deleteRetrievedGoods(data) {
  return request({
    method: 'post',
    url: 'settle/handle/del-retrieved',
    data
  })
}

export function changeDiffAmount(data) {
  return request({
    method: 'post',
    url: 'settle/handle/change-diff-amount',
    data
  })
}
