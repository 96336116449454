<template>
  <div class="settle-handle-item">
    <van-cell
      v-if="data.status === 1"
      title="处理人"
      center
      :value="data.employee_name"
    />
    <van-cell
      v-if="data.status === 1"
      title="处理时间"
      center
      :value="data.handle_at"
    />
    <van-cell
      title="退货人"
      :value="data.after_order.user.nickname"
      center
    />
    <van-cell
      title="退货时间"
      :value="data.after_order.created_at"
      center
    />
    <van-cell
      title="验收人"
      :value="data.after_order.accept.nickname"
      center
    />
    <van-cell
      title="验收时间"
      :value="data.after_order.accept_datetime"
      center
    />
    <van-cell
      title="客户名称"
      :value="data.after_order?.line?.name + ' ' + data.after_order?.client?.name"
      center
    />
    <van-cell
      v-if="data.before_amount"
      value-class="settle-handle-item__amount"
      title="原差价金额"
      :value="'￥' + data.before_amount"
    />
    <van-cell
      v-if="data.change_amount_remark"
      title="修改差价金额备注："
      :label="data.change_amount_remark"
    />
    <van-cell
      value-class="settle-handle-item__amount"
      title="差价金额"
      :value="'￥' + data.amount"
      center
      is-link
      :arrow-direction="data.show_more ? 'up' : 'down'"
      @click="handleShowAfterGoods(data)"
    />
    <div v-if="data.show_more && data.after_goods_list.length > 0">
      <after-goods v-for="(goods, index) in data.after_goods_list" :key="index" :goods="goods" />
    </div>
    <div v-if="data.show_more && data.after_goods_diff_list.length > 0">
      <after-goods-diff v-for="(goods, index) in data.after_goods_diff_list" :key="index" :goods="goods" />
    </div>
    <van-cell
      title="查看详情"
      is-link
      center
      @click="toDetailView(data)"
    />
    <van-cell
      title="取回商品"
      is-link
      center
      @click="toRetrieveView(data)"
    />
    <van-button v-if="data.status === 0" block square type="info" size="small" @click="handleConfirm(data)">处理</van-button>
  </div>
</template>

<script>
import AfterGoods from './goods'
import AfterGoodsDiff from './after-goods-diff'

export default {
  name: 'SettleHandleItem',
  components: { AfterGoods, AfterGoodsDiff },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleConfirm(data) {
      this.$emit('handle-confirm', data)
    },
    toDetailView(afterOrderDiff) {
      this.$router.push({
        path: 'after-accept-detail',
        query: {
          line_id: afterOrderDiff.after_order.line_id,
          order_id: afterOrderDiff.after_order.id,
          is_finished: 1,
          client_id: afterOrderDiff.after_order.client_id
        }
      })
    },
    toRetrieveView() {
      this.$router.push({
        path: 'settle-handle-detail',
        query: {
          after_order_diff_id: this.data.id,
          status: this.data.status
        }
      })
    },
    handleShowAfterGoods(diff) {
      diff.show_more = !diff.show_more
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/styles/color.scss";
  .settle-handle-item {
    &__amount {
      color: $amountRedColor;
    }
    &__footer {
      background-color: #fff;
      padding: 15px;
    }
    .settle-handle-item__footer{
      padding: 0;
    }
  }
</style>
