var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "goods-info-container"
  }, [_c('div', {
    staticClass: "goods-info-container__left"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.goods.thumb_image
    }
  })], 1), _c('div', {
    staticClass: "goods-info-container__right"
  }, [_c('div', {
    staticClass: "goods-name"
  }, [_vm._v(" " + _vm._s(_vm.goods.name) + " ")]), _c('div', {
    staticClass: "size"
  }, [_vm._v(" 规格：1*" + _vm._s(_vm.goods.size) + " ")]), _vm.goods.good_volume - _vm.goods.accept_good_volume > 0 ? _c('div', {
    staticClass: "volume"
  }, [_vm._v(" 完好的差："), _c('van-tag', {
    attrs: {
      "type": "success"
    }
  }, [_vm._v(_vm._s(_vm.goods.good_volume - _vm.goods.accept_good_volume) + _vm._s(_vm.goods.least_unit))])], 1) : _vm._e(), _vm.goods.bad_volume - _vm.goods.accept_bad_volume > 0 ? _c('div', {
    staticClass: "volume"
  }, [_vm._v(" 损坏的差："), _c('van-tag', {
    attrs: {
      "type": "danger"
    }
  }, [_vm._v(_vm._s(_vm.goods.bad_volume - _vm.goods.accept_bad_volume) + _vm._s(_vm.goods.least_unit))])], 1) : _vm._e(), _vm.showRemark ? [_c('div', {
    staticClass: "size"
  }, [_vm._v(" 退货备注：" + _vm._s(_vm.goods.remark) + " ")]), _c('div', {
    staticClass: "size"
  }, [_vm._v(" 验收备注：" + _vm._s(_vm.goods.accept_remark) + " ")])] : _vm._e()], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }