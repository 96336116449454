var render = function () {
  var _vm$handleAfterOrderD, _vm$handleAfterOrderD2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settle-handle-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "退货差价",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "处理记录"
    },
    on: {
      "click-right": _vm.handleClickRight
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.afterOrderDiffs, function (afterOrderDiff, index) {
    return _c('div', {
      key: index,
      staticClass: "settle-handle-item-container"
    }, [_c('settle-handle-item', {
      attrs: {
        "data": afterOrderDiff
      },
      on: {
        "handle-confirm": _vm.handleConfirm
      }
    })], 1);
  })], 2), _c('my-dialog', {
    attrs: {
      "title": "补回差价"
    },
    on: {
      "confirm": _vm.confirmRequest
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "输入模式"
    }
  }, [_c('van-switch', {
    attrs: {
      "size": "20px"
    },
    model: {
      value: _vm.inputMode,
      callback: function callback($$v) {
        _vm.inputMode = $$v;
      },
      expression: "inputMode"
    }
  })], 1), !_vm.inputMode ? _c('van-cell', {
    attrs: {
      "title": "收款方式",
      "value": _vm.actionName,
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        _vm.showActions = true;
      }
    }
  }) : _c('div', [_c('van-field', {
    attrs: {
      "label": "现金",
      "placeholder": "请输入现金金额"
    },
    model: {
      value: _vm.cash,
      callback: function callback($$v) {
        _vm.cash = $$v;
      },
      expression: "cash"
    }
  }), _c('van-field', {
    attrs: {
      "label": "转账",
      "placeholder": "请输入转账金额"
    },
    model: {
      value: _vm.trans,
      callback: function callback($$v) {
        _vm.trans = $$v;
      },
      expression: "trans"
    }
  })], 1)], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.handleSelectAction
    },
    model: {
      value: _vm.showActions,
      callback: function callback($$v) {
        _vm.showActions = $$v;
      },
      expression: "showActions"
    }
  }), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.operations
    },
    on: {
      "select": _vm.handleSelectOperation
    },
    model: {
      value: _vm.showOperation,
      callback: function callback($$v) {
        _vm.showOperation = $$v;
      },
      expression: "showOperation"
    }
  }), _c('my-dialog', {
    attrs: {
      "title": "差价收款码",
      "show-cancel-button": false
    },
    model: {
      value: _vm.showPaymentQrcodeDialog,
      callback: function callback($$v) {
        _vm.showPaymentQrcodeDialog = $$v;
      },
      expression: "showPaymentQrcodeDialog"
    }
  }, [_c('div', {
    staticClass: "payment-qrcode"
  }, [_c('div', {
    staticClass: "qrcode-label"
  }, [_vm._v("客户：" + _vm._s((_vm$handleAfterOrderD = _vm.handleAfterOrderDiff) === null || _vm$handleAfterOrderD === void 0 ? void 0 : _vm$handleAfterOrderD.after_order.client.name))]), _c('div', {
    staticClass: "qrcode-label"
  }, [_vm._v("金额："), _c('span', {
    staticClass: "amount"
  }, [_vm._v("￥" + _vm._s((_vm$handleAfterOrderD2 = _vm.handleAfterOrderDiff) === null || _vm$handleAfterOrderD2 === void 0 ? void 0 : _vm$handleAfterOrderD2.amount))])]), _c('van-image', {
    attrs: {
      "src": _vm.qrcodeData
    }
  })], 1)]), _c('my-dialog', {
    attrs: {
      "title": "修改金额"
    },
    on: {
      "confirm": _vm.handleChangeAmount
    },
    model: {
      value: _vm.showChangeAmountDialog,
      callback: function callback($$v) {
        _vm.showChangeAmountDialog = $$v;
      },
      expression: "showChangeAmountDialog"
    }
  }, [_c('van-field', {
    attrs: {
      "label": "金额",
      "clearable": "",
      "placeholder": "金额必须大于0",
      "type": "number"
    },
    model: {
      value: _vm.changeAmount,
      callback: function callback($$v) {
        _vm.changeAmount = $$v;
      },
      expression: "changeAmount"
    }
  }), _c('van-field', {
    attrs: {
      "label": "备注",
      "placeholder": "备注最多100个字",
      "clearable": "",
      "type": "textarea"
    },
    model: {
      value: _vm.changeAmountRemark,
      callback: function callback($$v) {
        _vm.changeAmountRemark = $$v;
      },
      expression: "changeAmountRemark"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }