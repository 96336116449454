var render = function () {
  var _vm$data$after_order, _vm$data$after_order$, _vm$data$after_order2, _vm$data$after_order3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settle-handle-item"
  }, [_vm.data.status === 1 ? _c('van-cell', {
    attrs: {
      "title": "处理人",
      "center": "",
      "value": _vm.data.employee_name
    }
  }) : _vm._e(), _vm.data.status === 1 ? _c('van-cell', {
    attrs: {
      "title": "处理时间",
      "center": "",
      "value": _vm.data.handle_at
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "退货人",
      "value": _vm.data.after_order.user.nickname,
      "center": ""
    }
  }), _c('van-cell', {
    attrs: {
      "title": "退货时间",
      "value": _vm.data.after_order.created_at,
      "center": ""
    }
  }), _c('van-cell', {
    attrs: {
      "title": "验收人",
      "value": _vm.data.after_order.accept.nickname,
      "center": ""
    }
  }), _c('van-cell', {
    attrs: {
      "title": "验收时间",
      "value": _vm.data.after_order.accept_datetime,
      "center": ""
    }
  }), _c('van-cell', {
    attrs: {
      "title": "客户名称",
      "value": ((_vm$data$after_order = _vm.data.after_order) === null || _vm$data$after_order === void 0 ? void 0 : (_vm$data$after_order$ = _vm$data$after_order.line) === null || _vm$data$after_order$ === void 0 ? void 0 : _vm$data$after_order$.name) + ' ' + ((_vm$data$after_order2 = _vm.data.after_order) === null || _vm$data$after_order2 === void 0 ? void 0 : (_vm$data$after_order3 = _vm$data$after_order2.client) === null || _vm$data$after_order3 === void 0 ? void 0 : _vm$data$after_order3.name),
      "center": ""
    }
  }), _vm.data.before_amount ? _c('van-cell', {
    attrs: {
      "value-class": "settle-handle-item__amount",
      "title": "原差价金额",
      "value": '￥' + _vm.data.before_amount
    }
  }) : _vm._e(), _vm.data.change_amount_remark ? _c('van-cell', {
    attrs: {
      "title": "修改差价金额备注：",
      "label": _vm.data.change_amount_remark
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "value-class": "settle-handle-item__amount",
      "title": "差价金额",
      "value": '￥' + _vm.data.amount,
      "center": "",
      "is-link": "",
      "arrow-direction": _vm.data.show_more ? 'up' : 'down'
    },
    on: {
      "click": function click($event) {
        return _vm.handleShowAfterGoods(_vm.data);
      }
    }
  }), _vm.data.show_more && _vm.data.after_goods_list.length > 0 ? _c('div', _vm._l(_vm.data.after_goods_list, function (goods, index) {
    return _c('after-goods', {
      key: index,
      attrs: {
        "goods": goods
      }
    });
  }), 1) : _vm._e(), _vm.data.show_more && _vm.data.after_goods_diff_list.length > 0 ? _c('div', _vm._l(_vm.data.after_goods_diff_list, function (goods, index) {
    return _c('after-goods-diff', {
      key: index,
      attrs: {
        "goods": goods
      }
    });
  }), 1) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "查看详情",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.toDetailView(_vm.data);
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "取回商品",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.toRetrieveView(_vm.data);
      }
    }
  }), _vm.data.status === 0 ? _c('van-button', {
    attrs: {
      "block": "",
      "square": "",
      "type": "info",
      "size": "small"
    },
    on: {
      "click": function click($event) {
        return _vm.handleConfirm(_vm.data);
      }
    }
  }, [_vm._v("处理")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }