<template>
  <div class="settle-handle-container">
    <my-nav-bar
      title="退货差价"
      left-text="返回"
      left-arrow
      right-text="处理记录"
      @click-right="handleClickRight"
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-for="(afterOrderDiff, index) in afterOrderDiffs" :key="index" class="settle-handle-item-container">
        <settle-handle-item
          :data="afterOrderDiff"
          @handle-confirm="handleConfirm"
        />
      </div>
    </div>

    <my-dialog v-model="showDialog" title="补回差价" @confirm="confirmRequest">
      <van-cell title="输入模式">
        <van-switch v-model="inputMode" size="20px" />
      </van-cell>
      <van-cell v-if="!inputMode" title="收款方式" :value="actionName" is-link @click="showActions = true" />
      <div v-else>
        <van-field v-model="cash" label="现金" placeholder="请输入现金金额" />
        <van-field v-model="trans" label="转账" placeholder="请输入转账金额" />
      </div>
    </my-dialog>

    <van-action-sheet v-model="showActions" :actions="actions" @select="handleSelectAction" />

    <van-action-sheet v-model="showOperation" :actions="operations" @select="handleSelectOperation" />

    <my-dialog
      v-model="showPaymentQrcodeDialog"
      title="差价收款码"
      :show-cancel-button="false"
    >
      <div class="payment-qrcode">
        <div class="qrcode-label">客户：{{ handleAfterOrderDiff?.after_order.client.name }}</div>
        <div class="qrcode-label">金额：<span class="amount">￥{{ handleAfterOrderDiff?.amount }}</span></div>
        <van-image :src="qrcodeData" />
      </div>
    </my-dialog>

    <my-dialog
      v-model="showChangeAmountDialog"
      title="修改金额"
      @confirm="handleChangeAmount"
    >
      <van-field v-model="changeAmount" label="金额" clearable placeholder="金额必须大于0" type="number" />
      <van-field v-model="changeAmountRemark" label="备注" placeholder="备注最多100个字" clearable type="textarea" />
    </my-dialog>

  </div>
</template>

<script>
import settleHandleApi, { changeDiffAmount } from '@/api/settle_handle'
import SettleHandleItem from './components/item'
import MyNavBar from '@/components/my-nav-bar'
import MyDialog from '@/components/my-dialog'
import qrcode from 'qrcode'
export default {
  name: 'SettleHandle',
  components: { SettleHandleItem, MyNavBar, MyDialog },
  data() {
    MyDialog
    return {
      afterOrderDiffs: null,
      showEmpty: false,
      handleAfterOrderDiff: null,
      showDialog: false,
      inputMode: false,
      handleType: 2,
      cash: '',
      trans: '',
      showActions: false,
      actions: [
        { name: '现金', value: 1 },
        { name: '线上支付', value: 2 },
        { name: '转账', value: 3 }
      ],
      actionName: '线上支付',
      showPaymentQrcodeDialog: false,
      qrcodeData: null,
      showOperation: false,
      operations: [
        { name: '修改金额', value: 1 },
        { name: '补回差价', value: 2 }
      ],
      showChangeAmountDialog: false,
      changeAmount: '',
      changeAmountRemark: ''
    }
  },
  watch: {
    afterOrderDiffs() {
      this.showEmpty = this.afterOrderDiffs.length === 0
    }
  },
  beforeRouteLeave(to, from, next) {
    // 跳转路径不是详情页，则清理keep-alive
    if (to.path !== '/after-accept-detail') { this.$store.commit('rmKeepAlive', this.$options.name) }
    next()
  },
  created() {
    // keep-alive
    this.$store.commit('setKeepAlive', this.$options.name)
    this.getList()
  },
  activated() {
    document.body.scrollTop = this.$route.meta.scrollTop
    this.getList()
  },
  methods: {
    getList() {
      this.beginLoad()
      settleHandleApi.list().then(res => {
        this.endLoad()
        this.afterOrderDiffs = res.data
        this.afterOrderDiffs.forEach(diff => {
          diff.show_more = false
        })
      })
    },
    handleConfirm(afterOrderDiff) {
      this.handleAfterOrderDiff = afterOrderDiff
      if (afterOrderDiff.type === 1) {
        this.showOperation = true
      } else {
        this.confirm('当前退货差价为签单类型，无需收款，请确认差价金额是否正确！').then(() => {
          this.beginLoad()
          settleHandleApi.handle(this.handleAfterOrderDiff.id, null).then(res => {
            this.success(res.msg)
            this.getList()
          })
        })
      }
    },
    confirmRequest() {
      if (!this.inputMode) {
        if (this.handleType === 1) {
          this.cash = this.handleAfterOrderDiff.amount
        } else if (this.handleType === 2) {
          const url = process.env.VUE_APP_PAYMENT_URL + 'after-diff/?after_order_num=' + this.handleAfterOrderDiff.after_order.num
          qrcode.toDataURL(url, { width: 200 }, (err, data) => {
            if (err) {
              this.fail('生成付款二维码失败')
              console.log(err)
              return
            }
            this.showPaymentQrcodeDialog = true
            this.qrcodeData = data
          })

          return
        } else {
          this.trans = this.handleAfterOrderDiff.amount
        }
      }

      this.beginLoad()
      const data = {
        cash: Number(this.cash),
        trans: Number(this.trans)
      }
      settleHandleApi.handle(this.handleAfterOrderDiff.id, data).then(res => {
        this.success(res.msg)
        this.getList()
      })
    },
    handleClickRight() {
      this.$router.push('settle-handle-record')
    },
    handleSelectAction(action) {
      this.showActions = false
      this.actionName = action.name
      this.handleType = action.value
    },
    handleSelectOperation(operation) {
      this.showOperation = false
      if (operation.value === 1) {
        // 修改金额
        this.showChangeAmountDialog = true
        this.changeAmount = this.handleAfterOrderDiff.amount
        this.changeAmountRemark = ''
      } else {
        // 补回差价
        this.showDialog = true
        this.inputMode = false
        this.handleType = 2
        this.cash = ''
        this.trans = ''
      }
    },
    handleChangeAmount() {
      this.beginLoad()
      const data = {
        after_order_diff_id: this.handleAfterOrderDiff.id,
        amount: this.changeAmount,
        change_amount_remark: this.changeAmountRemark
      }
      changeDiffAmount(data).then(() => {
        this.getList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color.scss';
  .settle-handle-container {
    padding: 15px 15px 5px 15px;
  }
  .settle-handle-item-container {
    padding-bottom: 10px;
  }
  .pay-mode {
    flex: 3;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
  }
  .payment-qrcode {
    text-align: center;
  }
  .qrcode-label {
    padding-top: 10px;
    font-size: 14px;
    color: #323232;
  }
  .amount {
    color: $amountRedColor;
  }
</style>
